import ReactGA from "react-ga4";
ReactGA.initialize("352606475");

function Home() {
    return (
<>
  
   <img className="ball" src="https://res.cloudinary.com/dihf0edss/image/upload/c_scale,w_400/v1675414117/lodes_atspulgs.0_p615kk.webp" alt='Gold ball with reflection'/>



</>
        );
    }
    
    export default Home;