
import './Styles.css';
import {  Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";

import Portfolio from "./pages/Portfolio";


function App() {
  return (
    <div className="App">
  <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          
          <Route path="portfolio" element={<Portfolio />} />
        
        </Route>
      </Routes>

    </div>
  );
}

export default App;
