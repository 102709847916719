import { Outlet, Link } from "react-router-dom";
import Footer from '../components/Footer';
import '../Styles.css';


const Layout = () => {
  return (
    <>
    <div class="flex-container">
    <div class="logo">  <Link to="/"><span class="ref">Popovs</span></Link></div>
      <div className="navigation">
     
           
      
      
            <Link to="/portfolio">Portfolio</Link>
           
      
      </div>

   </div>
      <Outlet />
     <Footer/>
   </>
  )
};

export default Layout;