import React from 'react';
import '../Styles.css';

const Footer = () => {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <footer className='footer'>
      <p>
         © {year} Maris Popovs
      </p>
    </footer>
  );
};

export default Footer;